<template>
	<div class="wrapper">
		<div class="track">
			<div class="navbar">
				<div class="content">
					<img src="~@/assets/blue_logo.png" />
					<router-link to="/" class="title">友邦物联</router-link>
					<router-link to="/" class="item">首页</router-link>
					<router-link to="/track" class="item">物流追踪</router-link>
					<router-link to="/about" class="item">关于我们</router-link>
				</div>
			</div>
			<header class="header">物流轨迹追踪</header>
			<div class="content flex-center">
				<div class="form" @click="handleFocus">
					<a-form-model ref="textAreaForm" :model="textAreaForm" v-bind="textAreaForm.layout">
						<a-form-model-item
							v-for="domain in textAreaForm.domains"
							:key="domain.key"
							@keyup.enter.native="onPressEnter($event, domain.key)"
							class="flex-start"
						>
							<a-input
								v-model="domain.trackNum"
								class="input-item"
								v-focus="picFocusStatus"
								:id="`input${domain.key}`"
								@click="clickInput"
								style="width: 100%;border: none"
							/>
							<a-icon
								class="delete-button"
								type="close-circle"
								@click="removeInput(domain)"
							/>
						</a-form-model-item>
					</a-form-model>
				</div>
				<div class="content-right">
					<a-button class="choose-btn" @click="showModal">
						<div v-if="selectCarrierItem">
							<img :src="selectCarrierItem.logo" alt="" />
							<div class="chinese-name">{{ selectCarrierItem.name }}</div>
	<!--						<div class="en-name">{{ selectCarrierItem.enName }}</div>-->
						</div>
						<div v-else>物流商</div>
					</a-button>
					<a-button type="primary" class="search-btn" @click="handleNetSearch">查询</a-button>
				</div>
			</div>
			<a-modal v-model="modalVisible" :footer="null" width="864px" :closable="false">
				<div class="modal-header flex-between-center">
					<div class="left">请选择物流商</div>
					<div class="right">
						<a-input
							placeholder="输入物流商中文或英文名称"
							class="search-input"
							v-model="searchValue"
							@keyup.enter.native="handleSearch"
						></a-input>
						<div class="cancel-btn flex-end-center">
							<a-icon type="close" @click="hideModal" />
						</div>
					</div>
				</div>
				<div class="container">
					<template v-if="this.searchValue">
						<div class="pane-content">
							<div class="customer-list flex-start">
								<div
									class="customer-item"
									v-for="item in searchList"
									:key="item.title"
									@click="clickCarrierItem(item)"
								>
									<img :src="item.logo" class="customer-img" />
									<span class="title">{{ item.name }}</span>
								</div>
							</div>
						</div>
					</template>
					<template v-else>
						<a-tabs :default-active-key="1" @change="clickTabs">
							<a-tab-pane v-for="item in tabList" :key="item.key" :tab="item.title">
							</a-tab-pane>
						</a-tabs>
						<div class="pane-content">
							<div class="customer-list flex-start">
								<div
									class="customer-item"
									v-for="item in renderList"
									:key="item.title"
									@click="clickCarrierItem(item)"
								>
									<img :src="item.logo" class="customer-img" />
									<span class="title">{{ item.name }}</span>
								</div>
							</div>
						</div>
					</template>
				</div>
			</a-modal>
			<div class="push"></div>
		</div>
		<div class="foot">
			<div class="f-content">
				Copyright 深圳友邦物联科技有限公司
				<a href="http://www.beian.miit.gov.cn/">粤ICP备17066262号-1</a>
			</div>
		</div>
	</div>
</template>

<script>
import api from '@/api/track'
export default {
	name: 'track',
	data() {
		return {
			textAreaForm: {
				layout: {
					labelCol: { span: 0 },
					wrapperCol: { span: 24 },
				},
				domains: [
					{
						trackNum: '',
						key: 0,
					},
				],
			},
			modalVisible: false,
			tabList: [
				{
					key: 1,
					title: '全球邮政',
				},
				{
					key: 2,
					title: '国际物流商',
				},
				{
					key: 3,
					title: '中国物流商',
				},
			],
			selectCarrierItem: null, // 选中的渠道商
			picFocusStatus: true,
			carriers: [], // 渠道列表
			china: [],
			international: [],
			globalPost: [],
			renderList: [], // 渲染列表
			searchValue: '',
			searchList: [], // 搜索结果列表
		}
	},
	directives: {
		focus: {
			inserted: function(el, { value }) {
				if (value) {
					el.focus()
				}
			},
		},
	},
	mounted() {
		this.init()
	},
	methods: {
		init() {
			const data = localStorage.getItem('carriers')
			if (!data) {
				this.getCarriers()
			} else {
				this.setupCarriersData(JSON.parse(data))
			}
		},
		// 点击整个form表单最后一个光标input聚焦
		handleFocus() {
			const input = document.getElementById(`input${this.textAreaForm.domains.length - 1}`)
			input.focus()
		},
		clickInput(e) {
			e.stopPropagation()
		},
		// 点击enter
		onPressEnter(e, index) {
			if (index === this.textAreaForm.domains.length - 1) {
				// 如果光标在最后一个input,创建新的input
				this.textAreaForm.domains.push({
					value: '',
					key: index + 1,
				})
			} else {
				// 聚焦到最后一个input
				this.handleFocus()
			}
		},
		// 删除input
		removeInput(item) {
			if (this.textAreaForm.domains.length > 1) {
				let index = this.textAreaForm.domains.indexOf(item)
				if (index !== -1) {
					this.textAreaForm.domains.splice(index, 1)
				}
			}
		},
		showModal() {
			this.modalVisible = true
		},
		async getCarriers() {
			const res = await api.carriers()
			if (res.data.code === 2000) {
				const data = res.data.data.data
				localStorage.setItem('carriers', JSON.stringify(data))
				this.setupCarriersData(data)
			} else {
				this.$message.error('网络异常')
			}
		},
		setupCarriersData(data) {
			this.carriers = data
			for (let i of data) {
				if (i.type === 'global-post') {
					this.globalPost.push(i)
				} else if (i.type === 'international') {
					this.international.push(i)
				} else if (i.type === 'china') {
					this.china.push(i)
				}
			}
			this.renderList = this.globalPost
		},
		hideModal() {
			this.modalVisible = false
		},
		clickTabs(key) {
			if (key === 1) {
				this.renderList = this.globalPost
			} else if (key === 2) {
				this.renderList = this.international
			} else {
				this.renderList = this.china
			}
		},
		// 本地搜索
		handleSearch(e) {
			this.searchList = []
			for (let i of this.carriers) {
				if (i.name.includes(e.target.value) || i.enName.toLowerCase().includes(e.target.value.toLowerCase())) {
					this.searchList.push(i)
				}
			}
		},
		// 网络查询
		handleNetSearch() {
			if (this.selectCarrierItem) {
				let params = []
				for (let i of this.textAreaForm.domains) {
					params.push({
						trackNum: i.trackNum,
						code: this.selectCarrierItem.code,
					})
				}
				console.log('params', params)
				// this.$router.resolve({
				// 	name: 'TrackResult',
				// 	query: { params, selectCarrierItem: this.selectCarrierItem },
                // })
                const obj = this.$router.resolve({
					name: 'TrackResult',
					query: { params: JSON.stringify(params), selectCarrierItem: JSON.stringify(this.selectCarrierItem) },
				})
				window.open(obj.href, '_blank')
			} else {
				this.$message.error('请先选择渠道商！')
			}
		},
		clickCarrierItem(item) {
			this.selectCarrierItem = item
			this.hideModal()
		},
	},
}
</script>

<style lang="less" scoped>

.wrapper {
	height: 100%;
}

.track {
	padding-top: 110px;
	margin: 0 auto -113px auto;
	box-sizing: border-box;
	overflow: hidden;
	.navbar {
		position: absolute;
		top: 0;
		left: 0;
		height: 60px;
		width: 100%;
		background: #f1f1f1;
		.content {
			width: 1000px;
			height: 31px;
			margin: 15px auto 0;
			// height: 80px;

			.item {
				margin-left: 40px;
				color: #4a4a4a;
				text-decoration: none;
				line-height: 31px;
				font-size: 15px;
			}

			.item:hover {
				color: #008cff;
			}

			.title {
				margin-left: 8px;
				color: #4A4A4A;
				text-decoration: none;
				line-height: 31px;
				font-weight: 800;
				font-size: 19px;
			}
		}
	}
	width: 1200px;
	height: 100%;
	color: #333;
	font-size: 16px;
	.first-input {
		width: 100%;
		height: 340px;
	}
	.header {
		font-size: 32px;
		font-weight: 600;
		text-align: center;
		margin-bottom: 30px;
	}
	.form {
		width: 600px;
		height: 240px;
		background: #ffffff;
		box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.5);
		border-radius: 5px;
		cursor: pointer;
		&:hover {
			border: 2px solid #008cff;
			box-shadow: 0px 0px 2px 1px #008cff;
		}
		/deep/ input:focus {
			border-color: transparent !important;
			outline: 1px solid transparent !important;
			&:focus {
				border-color: transparent;
			}
		}
		/deep/ .ant-input,
		.ant-input:focus {
			padding: 15px 20px;
			box-sizing: border-box;
			border-color: transparent;
			outline: 1px solid transparent !important;
			box-shadow: none;
			font-size: 16px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #4a4a4a;
		}
		/deep/ .ant-form-item-children {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
		.delete-button {
			margin-right: 30px;
			font-size: 16px;
			cursor: pointer;
		}
		/deep/ .ant-form-item {
			margin-bottom: 0;
		}
	}
	.content-right {
		margin-left: 20px;
		.choose-btn,
		.search-btn {
			display: block;
			width: 110px;
			height: 110px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			font-size: 20px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			.chinese-name,
			.en-name {
				font-size: 14px;
				font-weight: bolder;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			.en-name {
				font-weight: normal;
			}
		}
		.choose-btn {
			margin-bottom: 20px;
			border-radius: 5px;
			border: 2px solid #949495;
		}
		.search-btn {
			font-size: 20px;
			border-radius: 5px;
			border: 2px solid #008cff;
		}
	}
}

.push {
	height: 113px;
}
.footer {
	padding: 0;
	height: 113px;
	background-color: rgba(255, 255, 255, 1);
}
.f-content {
	margin-top: 0;
	text-align: center;
	padding: 52px 0 46px 0;
	line-height: 15px;
	font-size: 14px;
	color: rgba(153, 153, 153, 1);
	letter-spacing: 1px;
}
.f-content a,
.f-content a:hover {
	color: rgba(153, 153, 153, 1);
	text-decoration: none;
}

/deep/ .ant-modal-content {
	border-radius: 5px;
	.ant-modal-body {
		padding: 0;
		min-height: 450px;
		.modal-header {
			height: 60px;
			padding: 0 20px;
			box-sizing: border-box;
			background: #0062b3;
			border-radius: 5px;
			font-size: 20px;
			color: #fff;
			.right {
				.cancel-btn {
					width: 30px;
					height: 30px;
					display: inline-block;
					margin-left: 20px;
					cursor: pointer;
				}
			}
			.search-input {
				width: 249px;
				height: 30px;
				background: #ffffff;
				border-radius: 20px;
				border: 1px solid #979797;
			}
		}
	}
	.container {
		/deep/ .ant-tabs-nav-wrap {
			height: 54px;
		}
		.ant-tabs-bar {
			margin: 0;
		}
		.pane-content {
			width: 100%;
			padding: 13px;

			box-sizing: border-box;
			.auto-match-btn {
				width: 134px;
				height: 25px;
				background: #ff008c;
				border-radius: 5px;
				border: 1px solid #ff008c;
				color: #fff;
				margin-bottom: 40px;
			}
			.customer-list {
				width: 100%;
				height: 300px;
				overflow-y: auto;
				flex-wrap: wrap;
				.customer-item {
					cursor: pointer;
					width: 25%;
					&:hover {
						.title {
							border-bottom: 1px solid #000;
						}
					}
					.customer-img {
						width: 34px;
						height: 34px;
						margin-right: 10px;
					}
					.title {
						font-size: 14px;
					}
				}
			}
		}
	}
}
</style>
